<template>
  <div style="text-align: justify">
    <p class="mb-6">
      The objective of this exercise is to ensure your familiarity with the names and formulae of
      compounds you will encounter in freshman chemistry. Many of these will already be familiar to
      you from previous courses in chemistry and the exercise will constitute a refresher. Refer to
      Appendix: Nomenclature of Molecular and Inorganic Compounds in your lab manuals for more
      information regarding naming and molecular formulae.
    </p>

    <p class="mb-6">
      Write the correct molecular formulae corresponding to each of the following compounds.
    </p>

    <v-simple-table style="margin-bottom: 20px; max-width: 513px">
      <tbody>
        <tr>
          <td style="vertical-align: middle; width: 70%">
            <stemble-latex content="$\textbf{Name of Compound}$" />
          </td>
          <td>
            <stemble-latex content="$\textbf{Molecular Formula}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle; width: 30%">
            <chemical-latex content="a) lithium nitride" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputA" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="b) phosphorus pentachloride" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputB" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="c) dichlorine heptoxide" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputC" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="d) xenon hexafluoride" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputD" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="e) iron(III) sulfate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputE" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="f) lithium dihydrogen phosphate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputF" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="g) copper(I) sulfate tetrahydrate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputG" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="h) mercury(I) chloride" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputH" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="i) lead(IV) oxide" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputI" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="j) ammonium dichromate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputJ" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="k) sodium selenate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputK" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="l) magnesium hypochlorite" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputL" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="m) potassium chromate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputM" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="n) zinc phosphate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputN" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="o) sodium sulfate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputO" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="p) hypochlorous acid" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputP" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="q) nitrous acid" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputQ" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="r) potassium permanganate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputR" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="s) ammonium phosphate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputS" :show-note="false" class="my-2" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <chemical-latex content="t) tin(II) carbonate" />
          </td>
          <td style="vertical-align: middle">
            <chemical-notation-input v-model="inputs.inputT" :show-note="false" class="my-2" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'UPEIReactionsPreLab2',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: null,
        inputE: null,
        inputF: null,
        inputG: null,
        inputH: null,
        inputI: null,
        inputJ: null,
        inputK: null,
        inputL: null,
        inputM: null,
        inputN: null,
        inputO: null,
        inputP: null,
        inputQ: null,
        inputR: null,
        inputS: null,
        inputT: null,
      },
    };
  },
};
</script>
